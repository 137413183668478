// extracted by mini-css-extract-plugin
export var aArrow1 = "Mb";
export var aArrow2 = "Nb";
export var aButton1 = "wb";
export var aButton2 = "xb";
export var aButton3 = "yb";
export var aButton4 = "zb";
export var aButton5 = "Ab";
export var aButtonHeaderLogin = "Bb";
export var aButtonHeaderRegister = "Cb";
export var aButtonHeaderSpLogin = "Fb";
export var aButtonLogin = "Eb";
export var aButtonRegister = "Db";
export var aLabel = "Qb";
export var aSelect = "Rb";
export var aTagAnnounce = "Jb";
export var aTagClose = "Ib";
export var aTagEntry = "Gb";
export var aTagFree = "Lb";
export var aTagLottery = "Kb";
export var aTagPayment = "Hb";
export var animationMarquee = "Sb";
export var pListController = "Ob";
export var pListController__inner = "Pb";