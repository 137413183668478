// extracted by mini-css-extract-plugin
export var aArrow1 = "Ta";
export var aArrow2 = "Ua";
export var aButton1 = "Ja";
export var aButton2 = "Ka";
export var aButton3 = "La";
export var aButton4 = "Ma";
export var aButton5 = "Na";
export var aButtonHeaderLogin = "Oa";
export var aButtonHeaderRegister = "Pa";
export var aButtonHeaderSpLogin = "Sa";
export var aButtonLogin = "Ra";
export var aButtonRegister = "Qa";
export var aDate = "Ia";
export var aEmpty = "Ea";
export var aInner = "Ha";
export var aText = "Va";
export var aTitle = "Fa";
export var animationMarquee = "Wa";
export var pNewsList = "Ga";